/* eslint-disable max-lines */

import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ---------- DANISH ---------------
 *| |_______________________________
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| ||::::::::::::::::::::::::::::::|
 *| ||::::::::::::::::::::::::::::::|
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| ||MMMMMMMM:::MMMMMMMMMMMMMMMMMMM|
 *| |'""""""""""""""""""""""""""""""'
 */
export const da: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Lokation',
    noLocationSelected: 'Ingen lokation valgt',
    invalidBookingId: 'Ingen booking fundet - ugyldigt booking ID',
    confirm: 'Bekræft',
    Booking: 'Booking',
    booking: 'booking',
    yourBooking: 'Din booking',
    Giftcard: 'Gavekort',
    giftcard: 'gavekort',
    campaign: 'kampagne',
    voucher: 'voucher',
    refund: 'Voucher',
    membership: 'medlemsskab',
    'membership-campaign': 'kampagne',
    yourGiftcard: 'Dit gavekort',
    configHeader: 'Både & varighed',
    durationHeading: 'Både & varighed',
    dateAndTime: 'Dato & udsejlingstid',
    yourInformation: 'Din information',
    extras: 'Tilvalg',
    extrasAndRules: 'Tilvalg & Regler',
    finalize: 'Færdiggør ordre',
    completeBooking: `Din booking er næsten klar.

        Færdiggør din bestilling ved at klikke på knappen til højre.`,
    completeBookingMobile: `Din booking er næsten klar.

        Færdiggør din bestilling via ordreoversigten i bunden af skærmen.`,
    numberOfUnits: 'Antal både',
    personsPerUnit: '8 pers. pr. båd',
    boat: 'båd',
    boats: 'både',
    hour: 'time',
    hours: 'timer',
    minutes: 'minutter',
    hourAbbr: 't',
    minuteAbbr: 'm',
    chooseLocation: 'Vælg lokation',
    currentBooking: 'Nuværende Booking',
    show: 'Vis',
    hide: 'Skjul',
    currentbooking: 'nuværende booking',
    currentgiftcard: 'nuværende gavekort',
    configureGiftcard: 'Konfigurer dit gavekort',
    durationInHours: 'Varighed',
    acceptTerms: 'Accepter vilkår og betingelser',
    subscribeToNewsletter:
      'Jeg vil gerne tilmelde mig nyhedsbrevet og modtage maritim inspiration og info om events',
    yes: 'Ja',
    no: 'Nej',
    subscribeToNewsletterShort: 'Tilmeld nyhedsbrev',
    next: 'Næste',
    prev: 'Forrige',
    page: 'Side',
    addToOrder: 'Tilføj til kurv',
    haveAVoucher: 'Brug en voucher',
    voucherCode: 'Voucher-kode',
    applyVoucher: 'Tilføj voucher',
    voucherApplied: 'En voucher er blevet tilføjet',
    toBePaid: 'Beløb til betaling',
    confirmPayment: 'Bekræft betaling',
    confirmBooking: 'Bekræft booking',
    confirmGiftcard: 'Bekræft gavekort',
    confirmMembership: 'Bekræft medlemsskab',
    cancelBooking: 'Annuller booking',
    cancelTextVoucherRefund1: `Er du sikker på at du vil annullere din booking?

Du vil modtage en email med en voucher med en værdi på `,
    cancelTextVoucherRefund2: `
Voucheren udløber om `,
    cancelTextVoucherRefund3: ` måneder.`,
    cancelTextRefund1: `
Hvis du ønsker en refunderering, skal du kontakte kundeservice.`,
    cancelTextRefund2: `
Fordi udsejlingen finder sted om mindre end `,
    cancelTextRefund3: ` timer, har du ikke mulighed for at modtage en refundering.`,
    cancelTextNoRefund1: `Er du sikker på at du vil annullere din booking?

Fordi udsejlingen finder sted om mindre end `,
    cancelTextNoRefund2: ` timer eller ingen betaling for bookingen er modtaget, vil din booking blive annulleret og du vil ikke modtage en refundering.`,
    canceledBooking: 'Vi er kede af at se dig gå!',
    canceledTextVoucherRefund: `En voucher er blevet udstedt og vil blive sendt til dig via e-mail snart.

Vi forstår, at planer kan ændre sig, og vi vil gerne vide, hvorfor du måtte aflyse din booking hos os. Din feedback er uvurderlig og hjælper os med at forbedre vores service.`,

    canceledTextNoRefund:
      'Vi forstår, at planer kan ændre sig, og vi vil gerne vide, hvorfor du måtte aflyse din booking hos os. Din feedback er uvurderlig og hjælper os med at forbedre vores service.',

    canceledTextInitial:
      'Bookingen er annulleret, klik på knappen herunder hvis du ønsker at booke en ny oplevelse.',
    canceledButtonTextInitial: 'Book ny oplevelse',
    cancellationFeedbackPrompt:
      'Fortæl os venligst årsagen til din aflysning ved at klikke på en af mulighederne nedenfor:',
    cancellationThankYou:
      'Tak for din feedback. Vi værdsætter din tid og håber at kunne betjene dig bedre i fremtiden.',
    pleaseWait: 'Vent venligst...',
    positiveBalance1: 'Din booking har en positiv balance.',
    positiveBalance2Refund:
      'Du kan bruge denne balance til at tilføje tilkøb til din booking, eller bede om en refundering ved at kontakte kundeservice.',
    positiveBalance2VoucherRefund:
      'Du kan bruge denne balance til at tilføje tilkøb til din booking, eller bede om en voucher ved at kontakte kundeservice.',
    positiveBalance3:
      'Du kan også ønske refunderingen på forhånd ved at sende en email til ',
    neutralBalance:
      'Din bestilling er afbalanceret - ingen yderligere betaling nødvendig.',
    refundEmailSubject: 'Refundering - booking ID: ',
    priceForSailing: 'Pris for sejlads',
    priceForExtras: 'Pris for tilvalg',
    priceForBooking: 'Pris for booking',
    cancellationWarranty: 'Afbestillingsgaranti',
    cancellationWarrantyHeading: 'Har du brug for ekstra sikkerhed?',
    cancellationWarrantyText1:
      'Ved at tilvælge vores afbestillingsgaranti, har du mulighed for at ombooke eller annullere din booking op til ',
    cancellationWarrantyText2: ' timer før udsejling.',
    thankYou: 'Tak',
    captain: 'kaptajn',
    bookingCompleted: 'Din booking er gennemført. Vi ser frem til at se dig på dokken.',
    practicalInfo1: 'Sørg venligst for at møde op på udlejjningen ',
    practicalInfo2: ' mindst 15 minutter før sejladsen begynder.',
    confirmationEmailSentTo: 'En bekræftelses-email er sendt til',
    learnMore: 'Læs mere',
    cancel: 'Annuller',
    fetching: 'Henter',
    loading: 'Behandler',
    selectDateToViewTimeslots: 'Vælg en dato for a se de ledige udsejlingstider',
    yourInformationBookingHeld:
      'Når du har udfyldt dine informationer, bliver din udsejlingstid reserveret i 10 minutter.',
    noOpenDays: 'Der en ingen åbne dage i den valgte måned',
    defaultPaymentSuccessText: `Din booking er gennemført. Vi ser frem til at se dig på pontonen.

Husk at ankomme på pontonen mindst 15 min. før sejladsen begynder.`,
    OR: 'ELLER',
    left: 'tilbage',
    goToCheckout: 'Gå til betaling',
    bookingHeldHeading: 'Din udsejlingstid er reserveret',
    bookingHeldText:
      'Efter 10 mintter, kan vi ikke garentere at din udsejlingstid stadig er ledig.',
    allRightsReserved: 'Alle rettigheder forbeholdes',
    cookieSettings: 'Cookie indstillinger',
    custom: 'Tilpasset',
    amount: 'Beløb',
    customAmount: 'Tilpasset beløb',
    selectPickUpLocation: 'Vælg hvor du vil stige ombord',
    selectDropOffLocation: 'Vælg hvor du vil sættes af',
    select: 'Vælg',
    selected: 'Valgt',
    includedInPrice: 'Inkluderet i prisen',
    viewOnMap: 'Se på kort',
    pickupLocation: 'Afhentning',
    dropoffLocation: 'Aflevering',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Modtagers information',
    optional: 'Valgfri',
    defaultPaymentSuccessText:
      'Mange tak fordi du har købt et gavekort til en unik bæredygtig oplevelse hos GoBoat.',
  },

  /**
   * Membership
   */
  membership: {
    membership: 'medlemskab',
    yourMembership: 'Dit medlemskab',
    defaultPaymentSuccessText: 'Tak for din tilmelding til GoBoat Plus',
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Timeantal',
    dateAndTime: 'Dato & Tidspunkt',
    selectDateToViewTimeslots: 'Vælg en dato for a se de ledige tider',
    defaultPaymentSuccessText: `Din booking er gennemført. Vi ser frem til at se dig.

Husk at ankomme mindst 15 min. før din booking begynder.`,
    boat: 'person',
    boats: 'personer',
    numberOfUnits: 'Antal personer',
    personsPerUnit: 'Max 8 personer',
    durationHeading: 'Antal & varighed',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'Fornavn',
    lastname: 'Efternavn',
    phone: 'Telefonnummer',
    email: 'E-mail',
    birthDate: 'Fødselsdato',
    birthDatePattern: '(ÅÅÅÅ-MM-DD)',
    zip: 'Postnummer',
    province: 'Region/Provins',
    streetAddress: 'Adresse',
    city: 'By',
    pin: 'Italian Fiscal Code',
  },

  /**Recipient */
  recipient: {
    name: 'Navn',
  },

  /**
   * Price
   */
  price: {
    price: 'Pris',
    allIncluded: 'Pris alt inkluderet',
    paid: 'Betalt',
    toBePaid: 'Til betaling',
    toBeRefunded: 'Til refundering',
    confirm: 'Bekræft',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: 'Hmm, vi kan ikke finde din booking...',
    noBookingContent: "Det ser ikke ud som om at vi har en booking i systemet med ID'et:",
    noRequestTitle: 'Leder du efter din booking?',
    noRequestContent: 'Det ser ikke ud som om du har søgt på en booking endnu.',
    giefId: "Indsæt venligst booking ID'et fra ordrebekræftelsesmailen.",
    getBooking: 'Hent booking',
  },

  /**
   * Error
   */
  error: {
    NoShopId: 'Intet shop ID angivet',
    NoBoatCount: 'Bookingen har ingen både.',
    NoAvailableTimeslots: 'Ingen tilgængelige tidspunkter.',
    SlotNoLongerAvailable:
      'Det valgte udsejlingstidspunkt er ikke længere ledigt, vælg venligst et andet tidspunkt.',
    BookingNotCreated:
      'Der skete en fejl. Bookingen er ikke blevet oprettet. Reload venligst siden og forsøg igen.',
    SelectStartTime: 'Vælg venligst et starttidspunkt for din booking.',
    WeekdayNotValid: 'Gavekortet kan ikke bruges på den valgte ugedag.',
    VoucherAlreadyAdded: 'Denne booking har allerede et gavekort tilknyttet.',
    VoucherNotValidForTimeslot:
      'Gavekortet er ikke gyldigt på det valgte udsejlingstidspunkt.',
    PercentVoucherAlreadyUsed:
      'Et procent-baseret gavekort er allerede tilknyttet denne booking.',
    VoucherDepleted: 'Dette gavekort kan ikke benyttes flere gange.',
    VoucherLocationInvalid: 'Gavekortet kan ikke benyttes på denne lokation.',
    VoucherWeekdayInvalid: 'Gavekortet kan ikke benyttes på den valgte ugedag.',
    VoucherDateInvalid: 'Gavekortet kan ikke benyttes på den valgte dato.',
    VoucherValidForInvalid: 'Gavekortet er ikke gyldigt.',
    VoucherInvalid: 'Gavekortet er ikke gyldigt.',
    InvalidVoucherCombination: 'Disse gavekort kan ikke benyttes sammen.',
    VoucherAlreadyApplied: 'Dette gavekort er allerede tilknyttet denne booking.',
    NotValidPayment: 'Betalingen er ikke gyldig - transaktionen er ikke gennemført.',
    ShopNotOpen: 'Lokationen er ikke åben.',
    ShopNoLongerOpen: 'Lokationen har ikke flere åbningstider på den valgte dato.',
    ShopHasNoPriceModels: 'Lokationen har ingen prismodeller.',
    NoShopsFound: 'Ingen lokationer fundet',
    UnknownError: 'Der opstod en ukendt fejl. Venligst genindlæs siden og prøv igen.',
    PriceMismatchError:
      'Prisen på det valgte udsejlingstidspunkt er ændret. Venligst genindlæs siden og prøv igen.',
    NotAvailable: 'Ikke tilgængelig',
    DepartureTimesNotAvailable:
      'Disse udsejlingstider er ikke tilgængelige med den valgte varighed:',
    NotAvailableForOnlineBooking: 'Udsejlingstider ikke tilgænglige online',
    PersonalDetailsInvalid: 'Personlige oplysninger er ugyldige',
    Alcoholerror: 'Mængden af alkohol overstiger det tilladte.',
  },

  /**
   * FAQ
   */
  faq: {
    title: 'Sådan køber du dit gavekort',
    p1: 'En unik kode og gavekortet (som .pdf) vil blive sendt til din email med instruktioner til hvordan det bruges. Gavekortet er gyldigt i',
    p2: 'måneder fra købsdatoen. Gavekortet kan ikke ombyttes til kontanter.',
    campaign:
      'En unik kode og gavekortet (som .pdf) vil blive sendt til din email med instruktioner til hvordan det bruges. Gavekortets gyldighed står på gavekortet. Gavekortet kan ikke ombyttes til kontanter.',
    show: 'Vis FAQ',
    hide: 'Skjul FAQ',
  },

  factBox: {
    close: 'Luk',
    seeMore: 'Se mere',
  },

  /**
   * Meta
   */
  meta: {
    description:
      'Lej en båd hos GoBoat og få en unik oplevelse på vandet. Der er plads til op til 8 personer i bådene, og det kræver hverken bevis eller erfaring at styre båden.',
  },

  faqList: [
    'Vælg antal timer til gavekortet',
    'Indtast din e-mail (din email, ikke den person du vil give gavekortet til)',
    'Indtast dine betalingsoplysninger og bekræft',
  ],
  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Vejr',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Ændrede planer',
    [CustomerFacingCancellationReason.SICKNESS]: 'Sygdom',
    [CustomerFacingCancellationReason.OTHER]: 'Andet',
  },
};
