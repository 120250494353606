/* eslint-disable max-lines */
import { CustomerFacingCancellationReason, SingleLanguageLocalization } from './types';

/**
 * ------------- ENGLISH ------------
 *| |_______________________________
 *| ||: III  ::::: III :::::  III ::|
 *| ||::: III  ::: III :::  III ::::|
 *| ||:::::: III : III : III :::::::|
 *| ||IIIIIIIIIIIIIIIIIIIIIIIIIIIIII|
 *| ||IIIIIIIIIIIIIIIIIIIIIIIIIIIIII|
 *| ||:::::: III : III : III :::::::|
 *| ||::: III  ::: III :::  III ::::|
 *| ||: III  ::::: III :::::  III ::|
 *| |'""""""""""""""""""""""""""""""'
 */
export const en: SingleLanguageLocalization = {
  /**
   * Product
   */
  product: {
    location: 'Location',
    noLocationSelected: 'No location chosen',
    invalidBookingId: 'Booking not found - invalid booking ID',
    confirm: 'Confirm',
    Booking: 'Booking',
    booking: 'booking',
    yourBooking: 'Your booking',
    Giftcard: 'Gift card',
    giftcard: 'gift card',
    campaign: 'campaign',
    voucher: 'voucher',
    refund: 'Voucher',
    membership: 'membership',
    'membership-campaign': 'campaign',
    yourGiftcard: 'Your gift card',
    configHeader: 'Boats & duration',
    durationHeading: 'Boats & duration',
    dateAndTime: 'Date & departure time',
    yourInformation: 'Your information',
    extras: 'Extras',
    extrasAndRules: 'Extras & Rules',
    finalize: 'Finalize order',
    completeBooking: `Your booking is almost confirmed.

        Complete your booking by clicking the button on the right.`,
    completeBookingMobile: `Your booking is almost confirmed.

        Complete your booking through the order overview at the bottom of the screen.`,
    numberOfUnits: 'No. of boats',
    personsPerUnit: '8 pers. pr. boat',
    boat: 'boat',
    boats: 'boats',
    hour: 'hour',
    hours: 'hours',
    minutes: 'minutes',
    hourAbbr: 'h',
    minuteAbbr: 'm',
    chooseLocation: 'Choose location',
    currentBooking: 'Current booking',
    show: 'Show',
    hide: 'Hide',
    currentbooking: 'current booking',
    currentgiftcard: 'current gift card',
    configureGiftcard: 'Configure your gift card',
    durationInHours: 'Duration',
    acceptTerms: 'Accept terms and conditions',
    subscribeToNewsletter:
      'I would like to receive GoBoat’s newsletter with maritime inspiration and info about events',
    yes: 'Yes',
    no: 'No',
    subscribeToNewsletterShort: 'Sign up for newsletter',
    next: 'Next',
    prev: 'Previous',
    page: 'Page',
    addToOrder: 'Add to order',
    haveAVoucher: 'Use a voucher',
    voucherCode: 'Voucher code',
    applyVoucher: 'Apply voucher',
    voucherApplied: 'A voucher has been applied',
    toBePaid: 'To be paid',
    confirmPayment: 'Confirm payment',
    confirmBooking: 'Confirm booking',
    confirmGiftcard: 'Confirm gift card',
    confirmMembership: 'Confirm membership',
    cancelBooking: 'Cancel booking',
    cancelTextVoucherRefund1: `Are you sure you want to cancel your booking?

You will receive an email with a voucher with the value of `,
    cancelTextVoucherRefund2: `
The voucher expires in `,
    cancelTextVoucherRefund3: ` months.`,
    cancelTextRefund1: `
If you want a refund, you need to contact customer service.`,
    cancelTextRefund2: `
As the time to sail out is less than `,
    cancelTextRefund3: ` hours, you are not able to receive a refund.`,
    cancelTextNoRefund1: `Are you sure you want to cancel your booking?

As the time to sail out is less than `,
    cancelTextNoRefund2: ` hours or no payment for the booking has been received, your booking will be canceled and you will not receive a refund.`,
    canceledBooking: "We're Sorry to See You Go!",
    canceledTextVoucherRefund: ` A voucher has been issued and will be emailed to you shortly.

We understand that plans can change, and we'd love to know why you had to cancel your booking with us. Your feedback is invaluable and helps us improve our services.`,

    canceledTextNoRefund:
      "We understand that plans can change, and we'd love to know why you had to cancel your booking with us. Your feedback is invaluable and helps us improve our services.",

    cancellationFeedbackPrompt:
      'Please let us know the reason for your cancellation by clicking one of the options below:',
    cancellationThankYou:
      'Thank you for your feedback. We appreciate your time and hope to serve you better in the future.',
    canceledTextInitial:
      'The booking is canceled, click the button below if you would like to book a new experience.',
    canceledButtonTextInitial: 'Book new experience',
    pleaseWait: 'Please wait...',
    positiveBalance1: 'Your booking has a positive balance.',
    positiveBalance2Refund:
      'You can use this balance to purchase extras, or you can ask for a refund by contacting customer service.',
    positiveBalance2VoucherRefund:
      'You can use this balance to purchase extras, or you can ask for a voucher by contacting customer service.',
    positiveBalance3:
      'You can also ask for the refund in advance by sending an email to ',
    neutralBalance: 'Your order is balanced - no additional payment required.',
    refundEmailSubject: 'Refund - booking ID: ',
    priceForSailing: 'Price for sailing',
    priceForExtras: 'Price for extras',
    priceForBooking: 'Price for booking',
    cancellationWarranty: 'Cancellation protection',
    cancellationWarrantyHeading: 'Not sure you can make it?',
    cancellationWarrantyText1:
      'By adding our cancellation protection, you will be able to reschedule or cancel your booking up to ',
    cancellationWarrantyText2: ' hours before sail-out.',
    thankYou: 'Thank you',
    captain: 'captain',
    bookingCompleted:
      'Your booking is completed. We look forward to seeing you at the docks.',
    practicalInfo1: 'Please make sure that you are at the rental base ',
    practicalInfo2: ' at least 15 minutes before your sail-out time.',
    confirmationEmailSentTo: 'A confirmation email has been sent to',
    learnMore: 'Learn more',
    cancel: 'Cancel',
    fetching: 'Loading',
    loading: 'Loading',
    selectDateToViewTimeslots: 'Select a date to get the available timeslots',
    yourInformationBookingHeld:
      'Once you have filled in your information, your departure time will be reserved for 10 minutes.',
    noOpenDays: 'There are no open days in the selected month',
    defaultPaymentSuccessText: `Thank you for your booking, we look forward to welcoming you aboard!

Please remember to arrive 15 minutes before your departure time.

If you have any questions before your sail please get in touch with our team.`,
    OR: 'OR',
    left: 'left',
    goToCheckout: 'Go to checkout',
    bookingHeldHeading: 'Your departure time is reserved',
    bookingHeldText:
      'After 10 minutes, we cannot guarantee that your departure time is still available.',
    allRightsReserved: 'All rights reserved',
    cookieSettings: 'Cookie settings',
    custom: 'Custom',
    amount: 'Amount',
    customAmount: 'Custom amount',
    selectPickUpLocation: 'Select where you want to board',
    selectDropOffLocation: 'Select where you want to be dropped off',
    select: 'Select',
    selected: 'Selected',
    includedInPrice: 'Included in price',
    viewOnMap: 'View on map',
    pickupLocation: 'Pickup',
    dropoffLocation: 'Dropoff',
  },

  /**
   * Giftcard
   */
  giftcard: {
    recipientInformation: 'Recipient information',
    optional: 'Optional',
    defaultPaymentSuccessText:
      'Thank you for you purchasing a gift card for a uniqe and sustainable experience at GoBoat.',
  },

  /**
   * Membership
   */
  membership: {
    membership: 'membership',
    yourMembership: 'Your membership',
    defaultPaymentSuccessText: 'Thank you for signing up for a GoBoat Plus membership',
  },

  /**
   * Sauna
   */
  sauna: {
    duration: 'Duration',
    dateAndTime: 'Date & Time',
    selectDateToViewTimeslots: 'Select a date to get the available timeslots',
    defaultPaymentSuccessText: `Thank you for booking. We look forward to welcome you.

Please remember to show up 15 min. before your timeslot.

If you have any questions around your booking, you are more than welcome to contact us.`,
    boat: 'person',
    boats: 'people',
    numberOfUnits: 'No. of people',
    personsPerUnit: 'Capacity: 8 people',
    durationHeading: 'People & duration',
  },

  /**
   * Customer
   */
  customer: {
    firstname: 'First name',
    lastname: 'Last name',
    phone: 'Phone number',
    email: 'E-mail',
    birthDate: 'Date of birth',
    birthDatePattern: '(YYYY-MM-DD)',
    streetAddress: 'Address',
    zip: 'Zip',
    province: 'Province',
    city: 'City',
    pin: 'Italian Fiscal Code',
  },

  /**
   * Recipient
   */
  recipient: {
    name: 'Name',
  },

  /**
   * Price
   */
  price: {
    price: 'Price',
    allIncluded: 'Price all included',
    paid: 'Paid',
    toBePaid: 'To be paid',
    toBeRefunded: 'To be refunded',
    confirm: 'Confirm',
  },

  /**
   * Lookup
   */
  lookup: {
    noBookingTitle: "Hmm, we can't find that booking...",
    noBookingContent: "We don't seem to have any booking in the system with the ID:",
    noRequestTitle: 'Looking for your booking?',
    noRequestContent: "It doesn't look like you requested any booking at all.",
    giefId:
      'Please supply the booking ID from the confirmation email you received from us.',
    getBooking: 'Get booking',
  },

  /**
   * Error
   */
  error: {
    NoShopId: 'The shop ID is not valid.',
    NoBoatCount: 'The booking contains no boats.',
    NoAvailableTimeslots: 'No available timeslots.',
    SlotNoLongerAvailable:
      'The selected time slot is not available. Please select a different time slot.',
    BookingNotCreated:
      'An error occurred. The booking has not been created. Please refresh the page and try again.',
    SelectStartTime: 'Please select a start time for your booking.',
    WeekdayNotValid: 'The selected weekday is not eligible for this voucher.',
    VoucherAlreadyAdded: 'A voucher has already been added to this booking.',
    VoucherNotValidForTimeslot:
      'The voucher is not valid on the selected departure time.',
    PercentVoucherAlreadyUsed: 'A voucher has already been added to this booking.',
    VoucherDepleted: 'The voucher is not valid anymore.',
    VoucherLocationInvalid: 'The voucher is not valid for the selected location.',
    VoucherWeekdayInvalid: 'The voucher is not valid for the selected weekday.',
    VoucherDateInvalid: 'The voucher is not valid for the selected date.',
    VoucherValidForInvalid: 'The voucher is not valid.',
    VoucherInvalid: 'The voucher is not valid.',
    InvalidVoucherCombination: 'These vouchers cannot be used together.',
    VoucherAlreadyApplied: 'The voucher has already been applied to the booking.',
    NotValidPayment:
      'The payment request is not valid - the transaction has not been completed.',
    ShopNotOpen: 'The shop is not open on the seleted date.',
    ShopNoLongerOpen: 'The shop has no more valid opening times on the selected date.',
    ShopHasNoPriceModels: 'The shop has no price models.',
    NoShopsFound: 'No locations found',
    UnknownError: 'An unknown error occured. Please refresh the page and try again.',
    PriceMismatchError:
      'The price for your selected time slot has changed. Please refresh the page and try again.',
    NotAvailable: 'Not available',
    DepartureTimesNotAvailable:
      'These departure times are not available with the selected duration:',
    NotAvailableForOnlineBooking: 'Not available for online bookings',
    PersonalDetailsInvalid: 'Your personal details are invalid.',
    Alcoholerror: 'The amount of alcohol exceeds the allowed limit.',
  },

  /**
   * FAQ
   */
  faq: {
    title: 'How to buy a gift certificate',
    p1: 'The gift card and unique code will be sent to your inbox with instructions on how to use it. Please keep this email safe as it is your record of purchase. The gift card can be redeemed for the monetary value of your purchase today within',
    p2: 'months for boat rental at the location you have selected. It cannot be redeemed for cash.',
    campaign:
      'The gift card and unique code will be sent to your inbox with instructions on how to use it. Please keep this email safe as it is your record of purchase. The gift card can be redeemed for the monetary value of your purchase today for boat rental at the location you have selected. See the expiration date on the gift card. It cannot be redeemed for cash.',
    show: 'Show FAQ',
    hide: 'Hide FAQ',
  },

  factBox: {
    close: 'Close',
    seeMore: 'See More',
  },

  /**
   * Meta
   */
  meta: {
    description:
      'Rent a boat at GoBoat and have a unique experience by water. The boats can carry as many as eight people, and neither licence nor experience is required to operate the boat.',
  },
  faqList: [
    'Choose the duration of the rental for the gift card',
    'Enter your e-mail address (this should be your email address, not the person you will give the card to)',
    'Enter your credit card details and confirm',
  ],

  cancellationReason: {
    [CustomerFacingCancellationReason.WEATHER]: 'Weather',
    [CustomerFacingCancellationReason.SCHEDULE]: 'Change of plans',
    [CustomerFacingCancellationReason.SICKNESS]: 'Sickness',
    [CustomerFacingCancellationReason.OTHER]: 'Other',
  },
};
