import { createContext, useCallback, useState } from 'react';

export interface DurationOption {
  priceLow: number;
  priceHigh: number;
  durationString: any;
  durationNumber: number;
}

const defaultDurationOptions: DurationOption[] = [];

interface DurationOptionsContextValue {
  durationOptions: DurationOption[];
  dispatchDurationOptions: (options: DurationOption[]) => void;
}

const defaultContextValue: DurationOptionsContextValue = {
  durationOptions: defaultDurationOptions,
  dispatchDurationOptions: () => null,
};

export const durationOptionsContext = createContext(defaultContextValue);

interface DurationOptionsProviderProps {
  children: React.ReactNode;
}

const DurationOptionsProvider = ({ children }: DurationOptionsProviderProps) => {
  const [durationOptions, setDurationOptions] = useState(defaultDurationOptions);

  const dispatchDurationOptions = (options: DurationOption[]) => {
    const sorted = [...options].sort((a, b) => a.durationNumber - b.durationNumber);
    setDurationOptions(sorted);
  };

  const dispatchDurationOptionsWithReferenceEquality = useCallback(
    dispatchDurationOptions,
    [setDurationOptions]
  );

  return (
    <durationOptionsContext.Provider
      value={{
        durationOptions,
        dispatchDurationOptions: dispatchDurationOptionsWithReferenceEquality,
      }}
    >
      {children}
    </durationOptionsContext.Provider>
  );
};

export default DurationOptionsProvider;
